
import { defineComponent, PropType } from 'vue';
import useStatus from './useStatus';

export default defineComponent({
  name: 'CardItem',
  props: {
    server: {
      type: Object as PropType<StatusItem | BoxItem>,
      default: () => ({})
    }
  },
  setup(props) {
    const { getStatus, getRAMStatus, tableRowByteConvert,expandRowByteConvert } = useStatus(props);
    return {
      getStatus,
      getRAMStatus,
      tableRowByteConvert
    };
  }
});
